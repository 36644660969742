var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "member-club-card" }, [
    _c("div", { staticClass: "member-club-card__main" }, [
      _c("div", { staticClass: "fw500 flex bet a-center" }, [
        _c("div", {
          style: { "margin-right": "15px" },
          domProps: {
            textContent: _vm._s(
              _vm.data.member.first_name + " " + _vm.data.member.last_name
            ),
          },
        }),
      ]),
      _c(
        "div",
        {
          staticClass: "member-club-card__main_main",
          style: {
            display: "flex",
            "justify-content": "space-around",
            width: "100%",
            padding: "10px",
          },
          on: { click: _vm.data.click },
        },
        [
          _c("div", [
            _vm.data.member.join_date
              ? _c("div", {
                  staticClass: "grey",
                  domProps: { textContent: _vm._s(_vm.$t("Join date")) },
                })
              : _vm._e(),
            _vm.data.member.join_date
              ? _c("div", {
                  domProps: {
                    textContent: _vm._s(
                      _vm.formattedDate(_vm.data.member.join_date)
                    ),
                  },
                })
              : _c("div", { domProps: { textContent: _vm._s("-") } }),
            _c("div", {
              staticClass: "grey",
              style: { "margin-top": "10px" },
              domProps: { textContent: _vm._s(_vm.$t("Expiration date")) },
            }),
            _vm.data.member.expiration_date
              ? _c("div", {
                  domProps: {
                    textContent: _vm._s(
                      _vm.formattedDate(_vm.data.member.expiration_date)
                    ),
                  },
                })
              : _vm._e(),
          ]),
          _c("div", { staticClass: "member-club-card__main_name" }, [
            _c("div", [
              _c("div", {
                staticClass: "grey",
                domProps: { textContent: _vm._s(_vm.$t("Phone")) },
              }),
              _vm.data.member.phone
                ? _c("div", {
                    domProps: { textContent: _vm._s(_vm.data.member.phone) },
                  })
                : _c("div", { domProps: { textContent: _vm._s("-") } }),
            ]),
            _c("div", [
              _c("div", {
                staticClass: "grey",
                style: { "margin-top": "10px" },
                domProps: { textContent: _vm._s(_vm.$t("Points")) },
              }),
              _vm.data.member.points >= 0
                ? _c("div", {
                    domProps: { textContent: _vm._s(_vm.data.member.points) },
                  })
                : _c("div", { domProps: { textContent: _vm._s("-") } }),
            ]),
          ]),
          _c("div", { staticClass: "member-club-card__main_name" }, [
            _c("div", [
              _c("div", {
                staticClass: "grey",
                domProps: { textContent: _vm._s(_vm.$t("Birthday")) },
              }),
              _vm.data.member.birthdate
                ? _c("div", {
                    domProps: {
                      textContent: _vm._s(
                        _vm.formattedDate(_vm.data.member.birthdate)
                      ),
                    },
                  })
                : _c("div", { domProps: { textContent: _vm._s("-") } }),
            ]),
            _c("div", { style: { "margin-top": "10px" } }, [
              _c("div", {
                staticClass: "grey",
                domProps: { textContent: _vm._s(_vm.$t("Happy day")) },
              }),
              _vm.data.member.happy_day
                ? _c("div", {
                    domProps: {
                      textContent: _vm._s(
                        _vm.formattedDate(_vm.data.member.happy_day)
                      ),
                    },
                  })
                : _c("div", { domProps: { textContent: _vm._s("-") } }),
            ]),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }